<template>
  <div class="flex justify-center flex-col">
    <div class="flex justify-center">
      <div class="container max-w-2xl p-4">
        <h1 class="text-2xl font-bold mb-4 text-center">メール作成</h1>
        <form @submit.prevent="handleSubmit" class="space-y-4 bg-white p-6">
          <div>
            <label for="header_url" class="block mb-1"
              >ヘッダー画像URL 任意</label
            >
            <input
              id="header_url"
              v-model="mail.header_url"
              type="text"
              class="w-full p-2 border rounded"
            />
          </div>

          <div>
            <label for="title" class="block mb-1">タイトル *</label>
            <input
              id="title"
              v-model="mail.title"
              type="text"
              class="w-full p-2 border rounded"
              required
            />
          </div>

          <div>
            <label for="content" class="block mb-1">本文 *</label>
            <textarea
              id="content"
              v-model="mail.content"
              class="w-full p-2 border rounded"
              rows="5"
              required
            ></textarea>
          </div>

          <div>
            <label for="target_user_id" class="block mb-1"
              >対象ユーザーID *</label
            >
            <input
              id="target_user_id"
              v-model.number="mail.target_user_id"
              type="number"
              class="w-full p-2 border rounded"
            />
          </div>

          <div>
            <label for="expiration_datetime" class="block mb-1"
              >有効期限（JST）任意</label
            >
            <input
              id="expiration_datetime"
              v-model="mail.expiration_datetime"
              type="datetime-local"
              class="w-full p-2 border rounded"
            />
          </div>

          <div class="flex justify-center">
            <button
              type="submit"
              class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
              :disabled="isLoading"
            >
              {{ isLoading ? "送信中..." : "作成" }}
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- メール一覧 -->
    <div class="container mx-auto p-4">
      <h2 class="text-2xl font-bold mb-4">
        メール一覧（更新はユーザーが取得する前なら有効）
      </h2>

      <div class="flex justify-between mb-4">
        <input
          v-model="searchQuery"
          class="border p-2 rounded w-1/3"
          placeholder="タイトルで検索..."
        />
      </div>

      <EasyDataTable
        :headers="headers"
        :items="mails"
        :search-value="searchQuery"
        :sort-by="sortBy"
        :sort-type="sortType"
        :rows-per-page="15"
        :rows-items="[15, 25, 50, 100]"
        buttons-pagination
        @sort="handleSort"
      >
        <template #item-header_url="item">
          <input
            :value="item.header_url"
            @input="updateField(item, 'header_url', $event.target.value)"
            class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
          />
        </template>

        <template #item-title="item">
          <input
            :value="item.title"
            @input="updateField(item, 'title', $event.target.value)"
            class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
          />
        </template>

        <template #item-content="item">
          <textarea
            :value="item.content"
            @input="updateField(item, 'content', $event.target.value)"
            class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
            rows="2"
          />
        </template>

        <template #item-target_user_id="item">
          <input
            :value="item.target_user_id"
            @input="
              updateField(item, 'target_user_id', Number($event.target.value))
            "
            type="number"
            class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
          />
        </template>

        <template #item-expiration_datetime="item">
          <input
            type="datetime-local"
            :value="formatDateForInput(item.expiration_datetime)"
            @input="
              updateField(item, 'expiration_datetime', $event.target.value)
            "
            class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
          />
        </template>

        <template #item-created_at="item">
          {{ formatDateTime(item.created_at) }}
        </template>

        <template #item-updated_at="item">
          {{ formatDateTime(item.updated_at) }}
        </template>

        <template #item-actions="item">
          <div class="flex space-x-2">
            <button
              @click="updateMail(item)"
              class="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600"
            >
              更新
            </button>
          </div>
        </template>
      </EasyDataTable>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted } from "vue";
import api from "../api/api.js";

export default {
  setup() {
    const toast = inject("toast");
    const isLoading = ref(false);
    const mails = ref([]);
    const searchQuery = ref("");
    const sortBy = ref("id");
    const sortType = ref("desc");
    const mail = ref({
      header_url: "",
      title: "",
      content: "",
      target_user_id: null,
      expiration_datetime: "",
    });

    const headers = [
      { text: "ID", value: "id", width: 80, sortable: true },
      { text: "ヘッダー画像", value: "header_url", width: 200, sortable: true },
      { text: "タイトル", value: "title", width: 200, sortable: true },
      { text: "内容", value: "content", width: 300, sortable: true },
      {
        text: "対象ユーザーID",
        value: "target_user_id",
        width: 120,
        sortable: true,
      },
      {
        text: "有効期限",
        value: "expiration_datetime",
        width: 200,
        sortable: true,
      },
      { text: "作成日時", value: "created_at", width: 150, sortable: true },
      { text: "更新日時", value: "updated_at", width: 150, sortable: true },
      { text: "操作", value: "actions", width: 150 },
    ];

    const fetchMails = async () => {
      try {
        const response = await api.getMails();
        mails.value = response.data.map((mail) => ({
          ...mail,
          _original: { ...mail },
        }));
      } catch (error) {
        console.error("メールの取得に失敗しました:", error);
        toast.error("メールの取得に失敗しました。", {
          position: "top-right",
          duration: 5000,
        });
      }
    };

    const handleSubmit = async () => {
      isLoading.value = true;
      try {
        // Convert JST datetime-local to UTC for API
        let expirationDate = null;
        if (mail.value.expiration_datetime) {
          expirationDate = new Date(
            mail.value.expiration_datetime
          ).toISOString();
        }

        const mailData = {
          ...mail.value,
          expiration_datetime: expirationDate,
        };

        await api.createMail(mailData);
        toast.success("メールが作成されました", {
          position: "top-right",
          duration: 5000,
        });
        clearForm();
        await fetchMails();
      } catch (error) {
        console.error("メールの作成に失敗しました:", error);
        toast.error("メールの作成に失敗しました", {
          position: "top-right",
          duration: 5000,
        });
      } finally {
        isLoading.value = false;
      }
    };

    const updateField = (item, field, value) => {
      const index = mails.value.findIndex((m) => m.id === item.id);
      if (index !== -1) {
        mails.value[index] = {
          ...mails.value[index],
          [field]: value,
        };
      }
    };

    const updateMail = async (mail) => {
      try {
        const updatedMail = { ...mail };
        delete updatedMail._original;

        if (updatedMail.expiration_datetime) {
          const date = new Date(updatedMail.expiration_datetime);
          updatedMail.expiration_datetime = date.toISOString();
        }

        await api.updateMail(mail.id, updatedMail);
        toast.success("メールが更新されました", {
          position: "top-right",
          duration: 5000,
        });
        await fetchMails();
      } catch (error) {
        console.error("メールの更新に失敗しました:", error);
        toast.error("メールの更新に失敗しました。", {
          position: "top-right",
          duration: 5000,
        });
        const index = mails.value.findIndex((m) => m.id === mail.id);
        if (index !== -1) {
          mails.value[index] = { ...mails.value[index]._original };
        }
      }
    };

    const deleteMail = async (mail) => {
      if (!confirm("このメールを削除してもよろしいですか？")) return;

      try {
        await api.deleteMail(mail.id);
        toast.success("メールが削除されました", {
          position: "top-right",
          duration: 5000,
        });
        await fetchMails();
      } catch (error) {
        console.error("メールの削除に失敗しました:", error);
        toast.error("メールの削除に失敗しました。", {
          position: "top-right",
          duration: 5000,
        });
      }
    };

    const handleSort = (event) => {
      sortBy.value = event.sortBy;
      sortType.value = event.sortType;
    };

    const formatDateTime = (dateString) => {
      if (!dateString) return "";
      const date = new Date(dateString);
      const jstDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);
      return jstDate.toLocaleString("ja-JP");
    };

    const formatDateForInput = (dateString) => {
      if (!dateString) return "";
      const date = new Date(dateString);
      const jstDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);
      return jstDate.toISOString().slice(0, 16);
    };

    const clearForm = () => {
      mail.value = {
        header_url: "",
        title: "",
        content: "",
        target_user_id: null,
        expiration_datetime: "",
      };
    };

    onMounted(fetchMails);

    return {
      mail,
      mails,
      isLoading,
      searchQuery,
      headers,
      sortBy,
      sortType,
      handleSubmit,
      updateMail,
      updateField,
      deleteMail,
      handleSort,
      formatDateTime,
      formatDateForInput,
    };
  },
};
</script>
